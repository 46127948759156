// eslint-disable react/display-name
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { FlagProps } from './types';
import { LOCALE_FLAG_MAP } from './consts';

const Flag = ({ locale, className }: FlagProps) => {
  const FlagIcon = useMemo(() => LOCALE_FLAG_MAP[locale] || null, [locale]);

  if (!FlagIcon) {
    return null; // Render nothing if the flag is not found
  }

  return (
    <div className={clsx('flex h-5 w-5 overflow-hidden rounded-full', className)}>
      <FlagIcon className="h-full w-5 object-cover" />
    </div>
  );
};

export default Flag;
