import SubDropdownItem from '@/components/molecules/SubDropdownItem';
import { HeaderItemDocumentData, Simplify } from '@/prismicio-types';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import ArrowRight from '/public/assets/svg/arrow-right.svg';
import {
  ContentRelationshipBannerFieldExtended,
  ContentRelationshipHeaderCategoryFieldExtended,
  ContentRelationshipItemLinkFieldExtended,
} from '@/prismicCustomTypes';
import clsx from 'clsx';

const DropdownItem = ({
  item,
  openTab,
  prevTab,
  hideMenu,
}: {
  item: Simplify<HeaderItemDocumentData> | undefined;
  openTab: number;
  prevTab: number;
  hideMenu: () => void;
}) => {
  const { categories } = item || {};
  const banner = item?.item_banner as ContentRelationshipBannerFieldExtended | undefined;

  return (
    <div
      style={{ order: openTab > -1 ? openTab : prevTab }}
      className={clsx(
        openTab > -1 ? 'max-h-[99999px]' : 'max-h-0',
        '-z-10 w-full lg:transition-[max-height] lg:duration-300',
      )}
    >
      <div className="pointer-events-none left-0 top-20 w-full overflow-hidden lg:absolute">
        <div
          className={clsx(
            'pointer-events-auto w-full grid-cols-2 bg-gray-50 lg:grid lg:rounded-[0.625rem] lg:border lg:border-gray-200 lg:bg-white lg:shadow-elevation-4',
            'z-0 origin-top lg:transition-accordion lg:duration-200 lg:ease-in',
            openTab > -1
              ? 'translate-y-0 overflow-hidden opacity-100'
              : 'pointer-events-none -translate-y-full opacity-0',
          )}
        >
          <div className="px-6 py-4 lg:px-8 lg:py-8">
            <div className="w-full gap-x-8 lg:flex">
              {categories?.map((item, index) => {
                const { data } = item.item as ContentRelationshipHeaderCategoryFieldExtended;
                return (
                  <div key={data?.name ?? index} className="pt-[22px] first:pt-0 lg:pt-0">
                    <span className="text-caption-3 font-bold text-gray-500">{data?.name}</span>

                    {!!data?.items?.length && (
                      <SubDropdownItem
                        hideMenu={hideMenu}
                        grid={categories?.map((item) => item.item)?.length < 2}
                        items={
                          data.items.map(
                            (item) => item.item,
                          ) as ContentRelationshipItemLinkFieldExtended[]
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {banner && (
            <div className="bg-gray-50 px-6 py-0 lg:px-8 lg:py-8">
              <h3 className="mb-4 text-caption-3 font-bold uppercase text-gray-500">
                {banner.data?.title}
              </h3>
              <div className="flex flex-col gap-6 lg:flex-row">
                <PrismicNextImage
                  className="w-full max-w-[13.5rem] rounded-lg object-contain object-top lg:w-auto"
                  field={banner.data?.image}
                />
                <div className="flex flex-col justify-between py-2">
                  <div>
                    <h4 className="mb-2 font-semibold text-gray-600">{banner.data?.header}</h4>
                    <p className="text-h10 text-gray-500">{banner.data?.copy}</p>
                  </div>
                  <PrismicNextLink
                    className="flex items-center text-button text-gray-600 hover:opacity-50"
                    field={banner.data?.button_link}
                    onClick={hideMenu}
                  >
                    {banner.data?.button_text}
                    <ArrowRight />
                  </PrismicNextLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DropdownItem;
