import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import clsx from 'clsx';

import Flag from '@/components/atoms/Flag';
import { ChevronDownIcon } from '@/public/assets/svg';
import { LOCALES_MAP } from '@/consts/locales';

import { LanguageSwitcherProps } from './types';

export const LanguageSwitcher = ({ locales, lang, position = 'bottom' }: LanguageSwitcherProps) => (
  <Menu as="div">
    <MenuButton
      className="flex w-full items-center justify-between rounded-full border border-gray-100 bg-white p-3 lg:w-auto lg:justify-center"
      aria-label="flag button"
    >
      <div className="flex flex-row items-center">
        <Flag locale={lang} />
        <span className="ml-2 font-medium text-gray-600 lg:hidden">{LOCALES_MAP[lang]}</span>
      </div>
      <ChevronDownIcon className="block h-4 w-4 lg:hidden" />
    </MenuButton>
    <MenuItems
      modal={false}
      anchor={position === 'bottom' ? 'bottom end' : 'top end'}
      className={clsx(
        'z-50 origin-top-right rounded-xl border border-gray-100 bg-white p-4 text-body-6 text-gray-600 focus:outline-none',
        { 'mt-1': position === 'bottom', 'mb-1': position === 'top' },
      )}
    >
      {locales.map((locale) => (
        <MenuItem key={locale.lang}>
          <a
            className="group flex w-full flex-row items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-gray-50"
            data-lang={locale.lang}
            href={locale.url}
          >
            <Flag locale={locale.lang} className="flex-shrink-0" />
            <p>{LOCALES_MAP[locale.lang]}</p>
          </a>
        </MenuItem>
      ))}
    </MenuItems>
  </Menu>
);
